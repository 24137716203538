body, html{
    margin: 0;
    height: 100%;        
    font-family: montserrat;        
}

html {
    scroll-behavior: smooth;
}

@font-face{
    font-family: montserrat;
    src: url(./font/montserrat_regular.otf);
}

@font-face{
    font-family: montserrat_bold;
    src: url(./font/montserrat_semibold.otf);
}
::-webkit-scrollbar{
    width: 10px;
}

::-webkit-scrollbar-track{        
    background-color: transparent;
}
body::-webkit-scrollbar-track-piece
{
   display:none;
}
::-webkit-scrollbar-thumb{
    background: rgba(0,0,0, 0.1);
    border-radius: 10px;   
}
hr {
    background-color: rgba(0, 0, 0, .15);
    height: 1px;
    border: none;
}

/* BASE */
.img-rounded{    
    border-radius: 30px;
}
.img-rectangle-rounded {
    border-radius: 8px;
}
.img-size-small {
    width: 40px;
    height: 40px;
}
img {
    width: 100%;
}
.no-margin {
    margin: 0;
}
.text-brown{
    color: #A4A4A4;
}
.italic {
    font-style: italic;
}
.text-center{
    text-align: center;
}

/* MARGIN PADDING */
.m-left-10 {
    margin-left: 10px;
}
.m-right-10 {
    margin-right: 10px;
}
.m-top-10 {
    margin-top: 10px;
}
.m-bottom-10 {
    margin-bottom: 10px;
}

.p-left-10 {
    padding-left: 10px;
}
.p-right-10 {
    padding-right: 10px;
}
.p-top-10 {
    padding-top: 10px;
}
.p-bottom-10 {
    padding-bottom: 10px;
}

.m-left-20 {
    margin-left: 20px;
}
.m-right-20 {
    margin-right: 20px;
}
.m-top-20 {
    margin-top: 20px;
}
.m-bottom-20 {
    margin-bottom: 20px;
}

.p-left-20 {
    padding-left: 20px;
}
.p-right-20 {
    padding-right: 20px;
}
.p-top-20 {
    padding-top: 20px;
}
.p-bottom-20 {
    padding-bottom: 20px;
}

.m-left-50 {
    margin-left: 50px;
}
.m-right-50 {
    margin-right: 50px;
}
.m-top-50 {
    margin-top: 50px;
}
.m-bottom-50 {
    margin-bottom: 50px;
}

.p-left-50 {
    padding-left: 50px;
}
.p-right-50 {
    padding-right: 50px;
}
.p-top-50 {
    padding-top: 50px;
}
.p-bottom-50 {
    padding-bottom: 50px;
}

/* GRID */
.grid, .grid-block {            
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 2px;
    counter-reset: div;
}

.grid div[class]:after {
    display: block;            
}

.col-1 {
    grid-column: auto/span 1;
}
.col-2 {
    grid-column: auto/span 2;
}
.col-3 {
    grid-column: auto/span 3;
}
.col-4 {
    grid-column: auto/span 4;
}
.col-5 {
    grid-column: auto/span 5;
}
.col-6 {
    grid-column: auto/span 6;
}
.col-7 {
    grid-column: auto/span 7;
}
.col-8 {
    grid-column: auto/span 8;
}
.col-9 {
    grid-column: auto/span 9;
}
.col-10 {
    grid-column: auto/span 10;
}
.col-11 {
    grid-column: auto/span 11;
}
.col-12 {
    grid-column: auto/span 12;
}
/* spanning rows , complete values missing*/

.row-2 {
    grid-row: auto/span 2;
}

@media only screen and (max-width : 810px){
    .grid-block{
        display: block;
    }
    #send-email {
        margin-top: 50px;
    }
    .home>.left{
        width: 100vw !important;        
    }
    .greeting {
        font-size: 7vw !important;        
    }
    .tags{
        font-size: 2vw !important;    
    }
}

/* @media only screen and (max-width : 1282px){    
    .nav {
        overflow: hidden;
    }
} */

@media only screen and (max-width : 1023px){    
    .nav-bar {
        display: block !important;
    }
    .nav-item {
        display: none;
    }
    .nav.responsive {position: fixed;}
    .nav.responsive .icon {
        position: absolute;
        right: 0;
        top: 0;
    }
    .nav.responsive a {
        float: none;
        display: block;
        text-align: left;
    }
}

/* NAV */
/* .nav ul li a{        
    text-decoration: none;
    color: white;    
}
.nav ul {    
    position: fixed;    
    list-style-type: none;
    margin: 0;    
    padding: 20px;
    width: 100%;
    text-align: center;
    background-color: black;
}
.nav ul li {
    display: inline;
    padding: 15px;  
    margin: 20px;  
}
.nav ul li:hover {
    cursor: pointer;
} */

/* Add a black background color to the top navigation */
.nav {
    /* background-color: #333; */
    background-color: #fff;    
    overflow: hidden;
    position: fixed;
    width: 100vw;
}

/* Style the links inside the navigation bar */
.nav a {
    float: left;        
    color: #000;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
}

.nav-bar {
    display: none;
}

/* SCROLL TOP */
.scrolltop {
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 20px;
    padding:20px;
    border-radius: 30%;
    background-color: black;
    color: white
}

/* HOME */
/* .home {           
    background-image: url(./assets/home.jpg);
    height: 100%;    
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
} */
.home>.left{                
    display: flex;
    flex-direction: column;
    justify-content: center;            
    font-family: montserrat_bold;            
    padding-left: 3vw;    
    height: 100vh;
    width: 50vw;
}
.greeting {
    font-size: 4vw;        
}
.tags{
    font-size: 1.5vw;    
}
.section-white{
    background-color: white;
}
.section-grey{
    background-color: rgba(0, 0, 0, .03);
}
.section-black{
    background-color: black;
}
.img-social-media {
    transition: transform 0.2s ease;
}
.img-social-media:hover {
    transform:scale(1.2);
}

/* PROFILE */
.profile{    
    /* height: 100vh;         */
    padding:3vw;       
    /* position: relative; */
}
#img-profile{
    width: 100%;
}
.profile>.content{
    width: 100vw;
}
.table-label{
    padding-right: 10vw;    
    padding-bottom: 2vh;
}
.table-value{    
    color: rgba(0, 0, 0, 0.5)    
}
.profile-description {
    font-style: italic;
    color: rgba(0, 0, 0, 0.5);
    width: 50vw;
    margin: 20px 0px 20px 0px;
    text-align: center;
}
.profile-information>.name{
    margin:0 !important
}

/* Working Experience */
.working-experience{        
    padding:3vw;    
}

/* Working Experience */
.organization{        
    padding:3vw;    
}


/* SKILL */
.skill {
    /* height: 100vh;         */
    padding:3vw;    
}

/* Project */
.project {
    /* height: 100vh;         */
    padding:3vw;    
}
.project a {    
    color: black;
}

/* CERTIFICATE & ACHIEVEMENT */
.certificate-achievement {
    /* height: 100vh;         */
    padding:3vw;    
}

/* FEATURED EVENT */
.featured-event {        
    padding:3vw;    
}
iframe, object, embed {
    max-width: 100%;
    max-height: 100%;
}
.bg-shadow {
    box-shadow: -2px 4px 10px 2px rgba(0,0,0, 0.2);
}
.img-crop-top {
    object-fit: cover;
    object-position: 100% 0%;
}

/* Related Link */
.related-link {
    /* height: 100vh;         */
    padding:3vw;    
}

/* Contact */
.contact{    
    padding:3vw;    
}
input, textarea, select {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    /* display: inline-block; */
    font-family: montserrat;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}
input:focus, textarea:focus {
    outline: none !important;
    border:1px solid #ccc;    
}
textarea {
    resize: vertical;
}
.btn-send{
    width: 100%;
    border: none;
    padding: 10px;
    border-radius: 5px;
    background-color:blue;
    color:white;
}
#error-message {
    text-align: center;    
    color: red;
    display: none;
}
.display-block {    
    display: block !important;
}
/* FOOTER */
.footer {    
    padding:2vw;    
    color: white;    
}